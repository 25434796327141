<template>
    <div class="page-header" :class="category">
        <div class="page-header-title" :style="{ fontSize: titleSize }">
            {{ title }}<small v-if="titleSmall"> {{ titleSmall }}</small>
        </div>

        <div class="page-header-title name" v-if="category === 'artist'">
            <template v-if="titleForArtist">
                {{ titleForArtist }}
            </template>
            <template v-else>
                {{ $store.state.user.userData.userName || '전문가' }}님!
            </template>
        </div>

        <div class="page-header-subtitle" v-if="subtitle || $slots.subtitle">
            <slot name="subtitle">{{ subtitle }}</slot>
        </div>

        <template v-if="category === 'client'">
            <div class="btn-client-wrap">
                <button type="button" class="btn-client estimate" @click="() => postOffer()">
                    맞춤 섭외
                    <div class="btn-client-txt ">
                        평균 12팀의 실시간 <br />무료 견적 받기
                    </div>
                </button>
                <button
                    type="button"
                    class="btn-client cast"
                    @click="moveToEventList"
                >
                    행사 프로그램
                    <div class="btn-client-txt">
                        행사·공연 프로그램 <br />견적 바로보기
                    </div>
                </button>
            </div>
        </template>

        <template v-if="category === 'artist'">
            <button
                type="button"
                class="btn-artist"
                @click="$router.push(`/offer/search`)"
            >
                <icon
                    src="img/common/search-l.png"
                    width="2.5rem"
                    height="4.8rem"
                    :cover="false"
                />
                원하는 일을 찾아보세요
            </button>

            <!-- <icon class="bg-artist" src="img/home/hand-hello.png" width="14rem" height="28rem" /> -->
        </template>
    </div>
</template>

<script>
import Icon from '@/components/common/Icon'

export default {
    components: {
        Icon,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        titleSize: {
            type: String,
        },
        titleSmall: {
            type: String,
        },
        titleForArtist: {
            type: String,
        },
        subtitle: {
            type: String,
        },
        category: {
            type: String,
            default: null,
        },
    },
    methods: {
        postOffer() {
            FB.AppEvents.logEvent('click', {
                desc: `일반회원 > 메인 > 의뢰등록 버튼 선택 시`,
            })
            this.$store.commit('resetPostOffer', {})
            this.$router.push(`/offer/post/0`)
        },
        moveToEventList() {
            this.$router.push({ path: '/artist/list', query: { genre: "행사패키지" } })        
        }
    },
}
</script>

<style lang="scss">
@import '@/assets/css/constant.scss';

.page-header {
    position: relative;
    padding: 3rem 0 3.5rem;

    .page-header-title {
        font-size: 2.8rem;
        font-weight: 600;
        color: #000;
        white-space: pre-line;
        small {
            font-size: 2.2rem;
        }
    }

    .page-header-subtitle {
        margin-top: 1rem;
        font-size: 2rem;
        line-height: 1.4;
        color: #6c6c6c;
        white-space: pre-line;
        strong {
            font-weight: 600;
        }
        .red-font {
            color: #F33841;
        }
        .grey-font {
            color: #999999;
        }
    }

    .btn-client-wrap {
        display: flex;
        margin-top: 4.4rem;
        .btn-client {
            position: relative;
            display: block;
            margin: 0;
            padding: 2.4rem 2rem;
            width: 99%;
            border: 0.07rem solid #eee;
            color: $font-color;
            background-color: $color-white;
            font-size: 2.4rem;
            border-radius: 1rem;
            text-align: left;
            font-weight: 600    ;
            box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.08);
            letter-spacing: -0.011rem;
            line-height: 3.4rem;
            & ~ .btn-client {
                margin-left: 2rem;
            }

            .btn-client-txt {
                margin-top: 0.8rem;
                margin-bottom: 8.8rem;
                color: #999;
                font-size: 1.8rem;
                line-height: 2.5rem;
                letter-spacing: -0.009rem;
                font-weight: 500;
            }

            &.cast {
                background: $color-white url(/img/home/cast.png?v=20240116) no-repeat
                    calc(100% - 2rem) calc(100% - 1.4rem) / 6.8rem 6.8rem;
            }
            &.estimate {
                background: $color-white url(/img/home/estimate.png?v=20240116) no-repeat
                    calc(100% - 2rem) calc(100% - 1.4rem) / 6.8rem 6.8rem;
            }
        }
    }

    .bg-client {
        position: static;
    }

    .btn-artist {
        display: block;
        position: relative;
        z-index: 1;
        margin: auto;
        margin-top: 1.5rem;
        padding: 0;
        width: 100%;
        border: 0;
        color: #979797;
        color: $color-deepLavender;
        background-color: #fff;
        font-size: 1.8rem;
        line-height: 5rem;
        height: 5rem;
        border-radius: 0.6rem;
        border: 1.5px solid $color-deepLavender;
        text-align: left;
        padding-left: 1.6rem;
        box-shadow: 0.1rem 0.1rem 0.3rem 0 #ffffff;

        .icon {
            position: absolute;
            right: 2rem;
            top: 0;
        }
    }

    .bg-artist {
        position: absolute;
        right: 2rem;
        bottom: -4rem;
        z-index: 0;
    }

    &.client {
        padding: 5.2rem 0;

        .page-header-title,
        .page-header-subtitle {
            text-align: center;
            font-size: 2.6rem;
            // color: #fff;
        }
        .page-header-subtitle {
            // opacity: 0.7;
            color: #000;
            font-size: 3.2rem;
            font-weight: 200;
            text-align: left;
            letter-spacing: -0.05rem;
        }
    }

    &.artist {
        .page-header-title {
            color: #000;
            font-size: 4rem;
            font-weight: 600;
            &.name {
                font-size: 3.2rem;
                font-weight: 200;
            }
        }
        .page-header-subtitle {
            font-size: 2.4rem;
        }
    }

    &.client,
    &.artist {
        .page-header-title {
            font-size: 3.9rem;
            line-height: 5.4rem;
            font-weight: 600;
            text-align: left;
            letter-spacing: -0.02rem;
        }
    }
}
</style>
