var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-header", class: _vm.category },
    [
      _c(
        "div",
        {
          staticClass: "page-header-title",
          style: { fontSize: _vm.titleSize }
        },
        [
          _vm._v(" " + _vm._s(_vm.title)),
          _vm.titleSmall
            ? _c("small", [_vm._v(" " + _vm._s(_vm.titleSmall))])
            : _vm._e()
        ]
      ),
      _vm.category === "artist"
        ? _c(
            "div",
            { staticClass: "page-header-title name" },
            [
              _vm.titleForArtist
                ? [_vm._v(" " + _vm._s(_vm.titleForArtist) + " ")]
                : [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$store.state.user.userData.userName || "전문가"
                        ) +
                        "님! "
                    )
                  ]
            ],
            2
          )
        : _vm._e(),
      _vm.subtitle || _vm.$slots.subtitle
        ? _c(
            "div",
            { staticClass: "page-header-subtitle" },
            [_vm._t("subtitle", [_vm._v(_vm._s(_vm.subtitle))])],
            2
          )
        : _vm._e(),
      _vm.category === "client"
        ? [
            _c("div", { staticClass: "btn-client-wrap" }, [
              _c(
                "button",
                {
                  staticClass: "btn-client estimate",
                  attrs: { type: "button" },
                  on: {
                    click: function() {
                      return _vm.postOffer()
                    }
                  }
                },
                [_vm._v(" 맞춤 섭외 "), _vm._m(0)]
              ),
              _c(
                "button",
                {
                  staticClass: "btn-client cast",
                  attrs: { type: "button" },
                  on: { click: _vm.moveToEventList }
                },
                [_vm._v(" 행사 프로그램 "), _vm._m(1)]
              )
            ])
          ]
        : _vm._e(),
      _vm.category === "artist"
        ? [
            _c(
              "button",
              {
                staticClass: "btn-artist",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.$router.push("/offer/search")
                  }
                }
              },
              [
                _c("icon", {
                  attrs: {
                    src: "img/common/search-l.png",
                    width: "2.5rem",
                    height: "4.8rem",
                    cover: false
                  }
                }),
                _vm._v(" 원하는 일을 찾아보세요 ")
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btn-client-txt " }, [
      _vm._v(" 평균 12팀의 실시간 "),
      _c("br"),
      _vm._v("무료 견적 받기 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btn-client-txt" }, [
      _vm._v(" 행사·공연 프로그램 "),
      _c("br"),
      _vm._v("견적 바로보기 ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }